* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

.card {
  border: 1px solid black;
  background-color: white;
  padding: 1rem;
}

.cardTitle {
  text-decoration: none;
  color: black;
  font-weight: 700;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  width: 100%;
  padding: 1rem 1rem;
}


@media only screen and (min-width: 576px) {
  .col-sm-1 { width: 8.33%; }
  .col-sm-2 { width: 16.66%; }
  .col-sm-3 { width: 25%; }
  .col-sm-4 { width: 33.33%; }
  .col-sm-5 { width: 41.66%; }
  .col-sm-6 { width: 50%; }
  .col-sm-7 { width: 58.33%; }
  .col-sm-8 { width: 66.66%; }
  .col-sm-9 { width: 75%; }
  .col-sm-10 { width: 83.33%; }
  .col-sm-11 { width: 91.66%; }
  .col-sm-12 { width: 100%; }
  .container { width: 540px; }
}

@media only screen and (min-width: 768px) {
  .col-md-1 { width: 8.33%; }
  .col-md-2 { width: 16.66%; }
  .col-md-3 { width: 25%; }
  .col-md-4 { width: 33.33%; }
  .col-md-5 { width: 41.66%; }
  .col-md-6 { width: 50%; }
  .col-md-7 { width: 58.33%; }
  .col-md-8 { width: 66.66%; }
  .col-md-9 { width: 75%; }
  .col-md-10 { width: 83.33%; }
  .col-md-11 { width: 91.66%; }
  .col-md-12 { width: 100%; }
  .container { width: 720px; }
}

@media only screen and (min-width: 992px) {
  .col-lg-1 { width: 8.33%; }
  .col-lg-2 { width: 16.66%; }
  .col-lg-3 { width: 25%; }
  .col-lg-4 { width: 33.33%; }
  .col-lg-5 { width: 41.66%; }
  .col-lg-6 { width: 50%; }
  .col-lg-7 { width: 58.33%; }
  .col-lg-8 { width: 66.66%; }
  .col-lg-9 { width: 75%; }
  .col-lg-10 { width: 83.33%; }
  .col-lg-11 { width: 91.66%; }
  .col-lg-12 { width: 100%; }
  .container { width: 960px; }
}

@media only screen and (min-width: 1200px) {
  .col-xl-1 { width: 8.33%; }
  .col-xl-2 { width: 16.66%; }
  .col-xl-3 { width: 25%; }
  .col-xl-4 { width: 33.33%; }
  .col-xl-5 { width: 41.66%; }
  .col-xl-6 { width: 50%; }
  .col-xl-7 { width: 58.33%; }
  .col-xl-8 { width: 66.66%; }
  .col-xl-9 { width: 75%; }
  .col-xl-10 { width: 83.33%; }
  .col-xl-11 { width: 91.66%; }
  .col-xl-12 { width: 100%; }
  .container { width: 1140px; }
}

@media only screen and (min-width: 1400px) {
  .col-xxl-1 { width: 8.33%; }
  .col-xxl-2 { width: 16.66%; }
  .col-xxl-3 { width: 25%; }
  .col-xxl-4 { width: 33.33%; }
  .col-xxl-5 { width: 41.66%; }
  .col-xxl-6 { width: 50%; }
  .col-xxl-7 { width: 58.33%; }
  .col-xxl-8 { width: 66.66%; }
  .col-xxl-9 { width: 75%; }
  .col-xxl-10 { width: 83.33%; }
  .col-xxl-11 { width: 91.66%; }
  .col-xxl-12 { width: 100%; }
  .container { width: 1320px; }
}