@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');

.time {
    font-family: 'Orbitron', sans-serif;
    display: block;
    text-align: center;
    font-size: 5rem;
}

.input {
    border: 0px;
    border-bottom: 1px solid black;
    font-family: 'Orbitron', sans-serif;

    font-size: 2rem;
    text-align: right;
}

.button {
    padding: 0.5rem 3rem;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    transition-duration: 0.2s;
    width: 100%;
}

.button:hover {
    background-color: black;
    color: white;
}