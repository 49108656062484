@import url('https://fonts.googleapis.com/css2?family=Cherry+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

.hidden {
    background-color: red;
    visibility: hidden; 
}
.header {
    background-color: white;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
}

.brand-link {
    font-family: 'Cherry Swash', cursive;
    text-transform: lowercase;
    color: black;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
}

.nav-list {
    list-style: none;
    display: flex;
    flex-direction: row;
}

.nav-item {
    margin-left: 2rem;

}

.nav-link {
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    color: black;
}

.nav-link:hover {
    text-decoration: underline;
}


